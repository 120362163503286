import React from 'react';

// Components
import { MarkdownTemplate } from 'templates';

// Layouts
import { SEOContent } from 'layouts';

// Hooks
import { useBylawsPageSectionsQuery } from 'hooks';

const BylawsPageTemplate = () => {
  const { sections } = useBylawsPageSectionsQuery();
  const pageTitle = 'Bylaws';

  return (
    <SEOContent>
      <MarkdownTemplate postTitle={pageTitle} sections={sections} />
    </SEOContent>
  );
};

export default BylawsPageTemplate;
